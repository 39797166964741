import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Contact = () => {
  const intl = useIntl()

  const useInput = initialValue => {
    const [value, set] = useState(initialValue)
    return {
      value,
      onChange: e => {
        set(e.target.value)
      },
    }
  }

  const name = useInput("")
  const email = useInput("")
  const message = useInput("")

  // ボタンの活性・非活性制御
  let result = name.value && email.value && message.value
  let buttonClass = result
    ? "w-full md:max-w-xs border border-gray-400 bg-teal-500 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white py-2 px-4 rounded"
    : "w-full md:max-w-xs border border-gray-400 bg-gray-300 cursor-not-allowed text-white py-2 px-4 rounded"

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "category.contact" })} />
      <section className="w-full md:w-3/4 flex flex-col items-center px-3">
        <article className="w-full flex flex-col border border-gray-400 mb-3 bg-white rounded">
          <div className="flex flex-col justify-start p-6">
            <h1 className="text-3xl font-bold pb-6">
              {intl.formatMessage({ id: "contact.contact" })}
            </h1>
            <p className="pt-3 pb-6">
              {intl.formatMessage({ id: "contact.p1" })}
              <span role="img" aria-label="plane">
                🛫
              </span>
            </p>
            <form
              className="w-full"
              name="contact"
              method="post"
              data-netlify-honeypot="bot-field"
              data-netlify="true"
              autocomplete="off"
              action="/info/thanks"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p className="hidden">
                <label>
                  Don’t fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </p>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label className="block tracking-wide text-gray-700 mb-2">
                    <span role="img" aria-label="pen">
                      🖋️
                    </span>
                    {intl.formatMessage({ id: "contact.name" })}
                  </label>
                  <input
                    id="name"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    name="name"
                    {...name}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label className="block tracking-wide text-gray-700 mb-2">
                    <span role="img" aria-label="mail">
                      ✉️
                    </span>
                    {intl.formatMessage({ id: "contact.mail" })}
                  </label>
                  <input
                    id="email"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="email"
                    name="email"
                    {...email}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label className="block tracking-wide text-gray-700 mb-2">
                    <span role="img" aria-label="message">
                      💬
                    </span>
                    {intl.formatMessage({ id: "contact.message" })}
                  </label>
                  <textarea
                    id="message"
                    className="no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                    name="message"
                    {...message}
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  className={buttonClass}
                  type="submit"
                  disabled={!result}
                >
                  {intl.formatMessage({ id: "contact.send" })}
                </button>
              </div>
            </form>
          </div>
        </article>
      </section>
    </Layout>
  )
}
export default Contact
